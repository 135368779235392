import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from 'moment';
// import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { ToastContainer, toast } from 'react-toastify';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { db } from '../firebase';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Gravatar from 'react-gravatar';
import {
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import { collection, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from "reakit/Dialog";
import { Button } from "reakit/Button";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import styled from "styled-components";
import ProgressBar from "@ramonak/react-progress-bar";
import rewards from '../rewards.json';
// import Minter from "./minter";
import { environment } from '../environments';
import { needle } from 'needle';
import Konami from "konami-code-js";
import { TwitterApi, usersIdFollowers } from 'twitter-api-v2';
import { faClose, faUnlock, faPencil } from '@fortawesome/free-solid-svg-icons';
import Avatars from "../avatar.json";
import Avatar from '../utils/avatar';
import NameGenerator from '../components/Namegenerator';



const bearerToken = environment.BEARER;

export const dialogStyles = styled.div`
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(-50%, -10%, 0) rotateX(90deg);
  &[data-enter] {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
`;

export const ProfileComponent = () => {
  const InitUserMetaData = {
    userName: '',
    avatar: 'Gravatar',
    bio: '',
    email: '',
    twitter: ''
}

const InitValue = {
  checkins: [],
  address: '',
  created: '',
  membersince: '',
  lastcheckin: '',
  totalPoints: 0,
  level: 0,
  rewards: [],
  tests: []
}

  const [currentSelectedUser, setcurrentSelectedUser] = useState(InitValue);
  const dialog = useDialogState();
  const dialogBadge = useDialogState();
  const dialogKonami = useDialogState();
  // const [checkinStatus, setCheckinStatus] = useState(false);
  // const [tempOrigCheckins, settempOrigCheckins] = useState([]);
  const [hideShit, sethideShit] = useState(false);
  const [isWalletCOnnected, setisWalletCOnnected] = useState(false);
  const [updateUserBool, setupdateUserBool] = useState(false);
  const { user } = useAuth0();
  const [currentBadgeInfo, setcurrentBadgeInfo] = useState({});
  const [userWalletInfo, setuserWalletInfo] = useState({});
  const [tempUserDataCheckins, settempUserDataCheckins] = useState([]);
  const [originCheckins, setoriginCheckins] = useState([]);
  const [tempUserRewards, settempUserRewards] = useState([]);
  const [tempUserBonusRewards, settempUserBonusRewards] = useState([]);
  const [tempHolderBonusRewards, settempHolderBonusRewards] = useState([]);
  const [maxLevel, setmaxLevel] = useState(0);
  const [ userMetaData, setUserMetaData] = useState(InitUserMetaData);
  const [ userMetaDataOriginal, setUserMetaDataOriginal] = useState(InitUserMetaData);
  const [newUserName, setNewUserName] = useState(
    {
      userName: '',
      walletAddress: ''
    }
  );
  let docRef = {};
  // const nowTime = moment().format("MMM Do YYYY");
  // const currentMonth = moment(new Date()).format('MMMM');

  

  useEffect(() => {
    const buildingArray = tempUserBonusRewards;
    const buildingUserWallet = userWalletInfo;
    console.log('userWalletInfo post bool ', userWalletInfo)
    if(updateUserBool) {
      settempUserBonusRewards(buildingArray);
      setuserWalletInfo(buildingUserWallet);
    }
  }, [updateUserBool]);

  useEffect(()=>{
    new Konami(async function () {
        // This part will be executed if « Up Up Down Down Left Right Left Right B A »
        // show konami easter egg
        dialogKonami.show()
        // deep clone users unlocked avatar codes
        let clonedEasterEggs = userMetaData?.easterEggs !== undefined ? JSON.parse(JSON.stringify(userMetaData.easterEggs)) : [];
        // add value to clone adn send value to DB for konami
        
        const containersAlready = clonedEasterEggs.includes('konami');
        if (containersAlready === false) {
          clonedEasterEggs.push('konami');
          docRef = doc(db, "purchasedTokens", user.nickname);
          await updateDoc(docRef, {
            easterEggs: clonedEasterEggs
           })
          .then((result) => {
            setuserWalletInfo(values => ({...values, "easterEggs": clonedEasterEggs}))
            dialog.hide();
            toast.success("SUCCESS: Earned new avatar!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          });
          
        } else {
          console.log('user has reward');
        }
    });
  },[userMetaData, userWalletInfo])

  useEffect(() => {
    gettingUserInfoFromFullList();
    // getFollowers();
  }, []);

  function isFirestoreTimestamp(obj) {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      obj.constructor.name === 'ut' &&
      'seconds' in obj &&
      typeof obj.seconds === 'number' &&
      'nanoseconds' in obj &&
      typeof obj.nanoseconds === 'number'
    );
  }

  class FirebaseTimestamp {
    constructor(seconds, nanoseconds) {
      this.seconds = seconds;
      this.nanoseconds = nanoseconds;
    }
  
    toDate() {
      return new Date(this.seconds * 1000 + this.nanoseconds / 1000000);
    }
  }

  const handleNameChange = (newName) => {
    // Do something with the new name, such as updating state
    console.log('Newly generated name:', newName);
    setUserMetaData(values => ({...values, userName: newName}))
  };
  
  function convertToUTFormat(date) {
    return new FirebaseTimestamp(date.seconds, date.nanoseconds);
  }

  const saveUser = async () => {
    let clonedUser = JSON.parse(JSON.stringify(userMetaData));
    // console.log('userMetaData.created ', userMetaData.created);
    // console.log('userMetaData.moment ',  moment(userMetaData.created));
    // console.log('userMetaData.moment ', userMetaData.created.toDate());
    // console.log('userMetaData ', userMetaData);
    // console.log('d.toUTCString() ', userMetaData.toUTCString());
    console.log('userMetaData.created ', userMetaData.created);
    if(isFirestoreTimestamp(userMetaData.created)) {
      console.log('if userMetaData.created ', userMetaData.created);
      clonedUser.created = userMetaData.created.toDate();
    } else {
      console.log('e;lse userMetaData.created ', userMetaData.created);
      const newSteveDate  = convertToUTFormat(userMetaData.created);
      console.log('newSteveDate ', newSteveDate);
      clonedUser.created = newSteveDate.toDate();
    }
    // clonedUser.created = moment(userMetaData.created).format("MMM YYYY");

    clonedUser.metaData.checkins = originCheckins;
    // clonedUser.metaData = userMetaData.metaData;

    let walletstuff = JSON.parse(JSON.stringify(userWalletInfo));
    walletstuff.avatar = clonedUser.avatar;
    walletstuff.userName = clonedUser.userName;
    setuserWalletInfo(walletstuff);
    console.log('clonedUser ', clonedUser);
    console.log('serWalletInfo.id ', user.nickname);
    updateDoc(doc(db, "purchasedTokens", user.nickname), 
      clonedUser
      )
      .then((result) => {
        console.log('result ', clonedUser)
        setcurrentSelectedUser(clonedUser);
        setNewUserName(values => ({...values, "userName": clonedUser.userName}))
        // setNewUserName(values => ({...values, "twitter": clonedUser.twitter}))
        // handle user update status
      });
      dialog.hide();
}

// this is the ID for @TwitterDev
const userId = 2244994945;
const url = `https://api.twitter.com/2/users/${userId}/followers`;
const bearerToken = environment.BEARER;

// const getFollowers = async () => {
//     let users = [];
//     let params = {
//         "max_results": 1000,
//         "user.fields": "created_at"
//     }

//     const options = {
//         headers: {
//             "User-Agent": "v2FollowersJS",
//             "authorization": `Bearer ${bearerToken}`
//         }
//     }

//     let hasNextPage = true;
//     let nextToken = null;
//     // console.log("Retrieving followers...");
//     while (hasNextPage) {
//         let resp = await getPage(params, options, nextToken);
//         if (resp && resp.meta && resp.meta.result_count && resp.meta.result_count > 0) {
//             if (resp.data) {
//                 users.push.apply(users, resp.data);
//             }
//             if (resp.meta.next_token) {
//                 nextToken = resp.meta.next_token;
//             } else {
//                 hasNextPage = false;
//             }
//         } else {
//             hasNextPage = false;
//         }
//     }

//     // console.log(users);
//     // console.log(`Got ${users.length} users.`);

// }

const getPage = async (params, options, nextToken) => {
    if (nextToken) {
        params.pagination_token = nextToken;
    }

    try {
        const resp = await needle('get', url, params, options);

        if (resp.statusCode != 200) {
            console.log(`${resp.statusCode} ${resp.statusMessage}:\n${resp.body}`);
            return;
        }
        return resp.body;
    } catch (err) {
        throw new Error(`Request failed: ${err}`);
    }
}

const isDisabled = (e) => {
  // console.log('disabled ', e);
  if (e.criteria > userWalletInfo?.totalPoints) {
    return true;
  } else {
    if(e.date !== undefined) {
      if (ifHasDate(e.date, userMetaData?.metaData?.checkins)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }
}

  const truncateWallet = (add) => {
    // console.log('add ', add)
    if (add === undefined) {
      return;
    }
    return add.substring(0, 13) + "..." + add.substring(36, 42);
  }

  const saveUserName = async () => {
    const formingWallet = userWalletInfo;
    docRef = doc(db, "purchasedTokens", user.nickname);
    await updateDoc(docRef, {
      userName: newUserName.userName !== '' ? newUserName.userName : formingWallet.userName,
      // walletAddress: newUserName.walletAddress !== '' ? newUserName.walletAddress : formingWallet.walletAddress
    })
    .then((result) => {
      dialog.hide();
      toast.success("SUCCESS: Updated username successfully!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      formingWallet.userName = newUserName.userName;
      setuserWalletInfo(formingWallet);
      sethideShit(true);
    });
  }
  
  const cancelSave = async () => {
    dialog.hide();
    const name = 'userName';
    const value = '';
    setNewUserName(values => ({...values, [name]: value}))
  }

  // const closeBadge = async () => {
  //   dialogBadge.hide();
  // }
  
  const gettingUserInfoFromFullList = async () => {
    let tempUserData = []
    docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    console.log('tell me something', docSnap.data());
    if (docSnap.exists()) {
      let tempUserDataCheckins = [];
      tempUserData = docSnap.data();

       // USER HAS A TOKEN
        // console.log('user ', tempUserData);
        if(tempUserData.metaData) {
          const formedDate = Date(tempUserData.created);
          // hawk
          let clonedDates = JSON.parse(JSON.stringify(tempUserData.metaData.checkins));
          setoriginCheckins(clonedDates);
          let tempUserCheckins = tempUserData.metaData.checkins;
          tempUserCheckins.userName = tempUserData.userName;
          tempUserData.metaData.checkins.forEach((date, i) => {
            tempUserCheckins[i] = moment(date).format("MMM Do YYYY");
          });
          if(tempUserData.rewards) {
            tempUserData.rewards.sort((a,b) => (a.rid < b.rid) ? 1 : ((b.rid < a.rid) ? -1 : 0));
            settempUserRewards(tempUserData.rewards);
          }
          if(tempUserData.bonusrewards) {
            tempUserData.bonusrewards.sort((a,b) => (a.rid < b.rid) ? 1 : ((b.rid < a.rid) ? -1 : 0));
            settempUserBonusRewards(tempUserData.bonusrewards);
          }
          // if(tempUserData.holderRewards) {
          //   tempUserData.holderRewards.sort((a,b) => (a.rid < b.rid) ? 1 : ((b.rid < a.rid) ? -1 : 0));
          //   settempHolderBonusRewards(tempUserData.holderRewards);
          // }
          user.created = moment(formedDate).format("MMM YYYY");
          user.lastVisit = moment().format('L');
          settempUserDataCheckins(tempUserCheckins);
        }
        if(tempUserData.userName) {
          sethideShit(true);
        }
        // console.log(holder, 'holder')
        if(tempUserData.walletAddress) {
          // console.log('it has a wallet')
          setisWalletCOnnected(true);
        }
        setuserWalletInfo(tempUserData);
        levelMax(tempUserData.level);
        // tempUserData
        user.id = user.nickname;
        user.totalCheckin = tempUserDataCheckins.length;

    }


   
        
  };


  // const holderCallBack = (callBackrewards) => {
  //   // this is where we find out what holder rewards they get
  //   // we need to update them on this component temporarily
  //   let buildingArray = tempUserBonusRewards;
  //   let buildingUserWallet = userWalletInfo;
  //   let preTotal = userWalletInfo.totalPoints;
  //   let newPoints = 0;
  //   callBackrewards.forEach((reward) => {
  //     newPoints = newPoints + reward.pointTotal;
  //     buildingArray.push(reward);
  //   });

  //   // add to bonus holders badges array..
  //   settempUserBonusRewards(buildingArray);
  //   // add points up..  
  //   buildingUserWallet.totalPoints = preTotal + newPoints;
  //   setuserWalletInfo(buildingUserWallet);
    
  //   // give an alert saying well done broski
  //   toast.success("SUCCESS: Wallet Verified and a ton of points earned!", {
  //     position: "top-right",
  //     autoClose: 1500,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined
  //   });

  //   setupdateUserBool(true);
  // };

  const ifHasDate = (rewardDate, checkins) => {
    console.log('rewardDate ', rewardDate);
    console.log('checkins ', checkins);
    // const tempVar = checkins.find(date =>  moment(date).format("MM/DD/YYYY") == moment(rewardDate).format("MM/DD/YYYY"));
    const tempVar2 = checkins?.find(item => item == moment(rewardDate).format("MMM Do YYYY"));
    console.log('tempVar2 ', tempVar2 )
    if (tempVar2 !== undefined) return true;
  }
  const levelMax = (level) => {
    rewards.forEach((reward) => {
      if (reward.level === level + 1) {
        setmaxLevel(reward.criteria);
      }
      // setting first level if its missing for some reason
      if (level == null) {
        const push = {name: 'First Checkin Reward!', reward: 'NFD Rewards Membership', image:'cherry', rid: 0};
        setmaxLevel(rewards[1].criteria);
        settempUserRewards([push]);
        
      }
    });
  }

  const badgeDetails = (badge) => {
    setcurrentBadgeInfo(badge);
  }


  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewUserName(values => ({...values, [name]: value}))
  }

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserMetaData(values => ({...values, [name]: value}))
  }


  return (
    <Container className="mb-5 overwrite">
      {/* <h1 className="mb-4">Profile</h1> */}
      <div className="leader-contain starty">
        <div className="user-inf-new">
          {/* <Gravatar 
            email={user.email} 
            className="grv rou nded-circle img-fluid profile-picture mb-3 mb-md-0"
          />  */}
          <Avatar address={user.email} avatar={userWalletInfo.avatar ? userWalletInfo.avatar : undefined} />
         {userWalletInfo.userName && 
              <h1 className={newUserName.userName && 'hidden'}>{ userWalletInfo.userName }</h1>
            }
            {newUserName && 
              <h1>{ newUserName.userName }</h1>
            }
            {!newUserName.userName && 
             <h1 className={hideShit ? 'hidden' : ''}>{user.nickname}</h1>
            }
    
              
            
            <p className="whitewallet">{ truncateWallet(userWalletInfo?.walletAddress)}</p>
            <DialogDisclosure className="open-dia" {...dialog} onClick={() => setUserMetaData(userWalletInfo)}><p className="edit-button">Edit Profile <FontAwesomeIcon icon="edit" className="editbut" /></p></DialogDisclosure>
            <p className="p-tag minus-bot">Member Since <br /><strong>{user.created}</strong></p>
            <p className="p-tag minus-bot plus">Last Visit<br /><strong>{ user.lastVisit}</strong></p>
            <p className="pointpro">{userWalletInfo.totalPoints}<br /><span className="lab">total points</span></p>
            <div className="stat-holders">
              <p className="p-tag">Checkins <br /> <strong>{tempUserDataCheckins.length}</strong></p>
              {/* <p className="p-tag bordera">Purchases  <br /><strong>{userWalletInfo.totalPoints -tempUserRewards.length}</strong></p> */}
              <p className="p-tag bordera">Level <br /> <strong>{userWalletInfo.level ? userWalletInfo.level : 1}</strong></p>
              <p className="p-tag">Rewards <br /><strong>{tempUserRewards.length + tempHolderBonusRewards.length}</strong></p>
            </div>
            <ProgressBar className="progress-bar-hawk" completed={userWalletInfo?.totalPoints} customLabel=" " maxCompleted={maxLevel} />
          <p className="progress-line">{maxLevel - userWalletInfo?.totalPoints} more points needed for Level {userWalletInfo.level ? userWalletInfo.level + 1 : 2}</p>
          {/* <div>
            <div>
              <span className={userWalletInfo.tokensOwned?.length > 0 ? 'holder-label' : 'hidden'}>Official NFD Holder:</span>
              {userWalletInfo.tokensOwned?.length > 0 &&
                  userWalletInfo.tokensOwned?.map((c, i) => (
                      <h1 className="mint-box-heading font-adj" key={i+c}>#{c} </h1>
                  ))
              }
            </div>
            {!userWalletInfo.walletAddress &&
              <>
                <Minter userId={userWalletInfo.id} walletStatus={isWalletCOnnected} totalPointsPass={userWalletInfo.totalPoints} dataCallback={holderCallBack}/>
                <br />
              </>
            }
            
          </div> */}
          <div className="badge-container">
            <span className="badge-label">earned badges:</span>
            <div className="badges bwu-contatiner">
            {tempUserRewards.map((d, i) => (
              <div className="badge bwu" key={i+d.image}>
                <DialogDisclosure className="open-dia" {...dialogBadge}  onClick={() => badgeDetails(d)}>
                  <div className={'leader-rank newbadge ' + d.image}></div>
                </DialogDisclosure>
              </div>
            ))}


            {tempUserBonusRewards.map((e, j) => (
              <div className="badge" key={j+e.image}>
                <DialogDisclosure className="open-dia" {...dialogBadge}  onClick={() => badgeDetails(e)}>
                  <div className={'leader-rank newbadge ' + e.image}></div>
                </DialogDisclosure>
              </div>
            ))}

            {tempHolderBonusRewards.map((e, j) => (
              <div className="badge" key={j+e.image}>
                <DialogDisclosure className="open-dia" {...dialogBadge}  onClick={() => badgeDetails(e)}>
                  <div className={'leader-rank newbadge ' + e.image}></div>
                </DialogDisclosure>
              </div>
            ))}
          
            
             
            </div>
            {/* <div className="twitter-stuff">
              <a href="https://twitter.com/nfdaddys?ref_src=twsrc%5Etfw" target="_blank" className="twitter-follow-button" data-show-count="false">Follow @nfdaddys</a>
            </div> */}
          </div>
        </div>
      </div>


      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        
       
        <Col md={4}>
        {/* {checkinStatus &&
          <div className="check-btn" > 
           <FontAwesomeIcon icon="check" className="mr-2" />Successful Check-in <span className="light-date">({nowTime})</span>
          </div>
           } */}
        </Col>
      </Row>
      {/* <div className="pad-container extra-pad">
      <h3>Next Reward:</h3>
      <span> {15 - tempUserDataCheckins.length} checkins needed to for next milestone reward. <span className="itally">(5 Total Check-ins)</span></span>
      </div> */}
      {/* <div className="pad-container extra-pad">
      <h3>Earned Rewards:</h3>
      <span>No rewards to diplay.</span>
      </div> */}
      {/* <hr/> */}

      <hr/>
      {/* <div className="profile-containers">
        <h3 className="pad-container">Most Recent Checkins:</h3>
    
        <table className="leader-table">
        <thead>
        <tr align="center">
                <th>Date</th>
                <th>Check In #</th>
            </tr>
        </thead>
        <tbody>
        {tempUserDataCheckins.slice(0, 5).map((c, i) => (
            <tr key={i}>
              <td>{c}</td>
              <td align="center">{tempUserDataCheckins.length - i}</td>
            </tr>
        ))}
        </tbody>
    </table>
      </div> */}
      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
      <ToastContainer />
      <DialogBackdrop {...dialog}  className="backstyle">
            <Dialog className="refix" {...dialog} aria-label="Welcome">
              <h3 className="claimh">Modify Profile</h3>
              <Button className="closebtn" onClick={dialog.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
              <div className="flutarsky">
               {/* <form>
               <div className="form-holder">
                <div><label className="labeler">New Username:</label>
                <input
                 type="text" 
                  name="userName"
                  value={newUserName.userName || ""}
                  placeholder={userWalletInfo?.userName}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                </div>
              </div>
             
                </form> */}

<Form>
                {/* <FormGroup>
                    <Label for="userName">User Name:</Label>
                    <Input type="text" name="userName" id="userName" 
                       value={userMetaData.userName || ""}
                        onChange={(e) => {handleChangeForm(e)}}
                        placeholder="Username"
                    />
                </FormGroup> */}
        {userWalletInfo.userName &&
          <NameGenerator
            originalName={userWalletInfo.userName || newUserName?.userName}
            display={true}
            regenerateButton={true}
            onNameChange={handleNameChange}
        />
        }
              
                      
                {/* <FormGroup>
                    <Label for="email">Twitter:</Label>
                    <Input type="text" name="twitter" id="twitter" 
                      value={userMetaData.twitter || ""}
                      placeholder="@TwitterName"
                      onChange={(e) => {handleChangeForm(e)}}
                    />
                </FormGroup> */}
                
                <FormGroup tag="fieldset">
                  {/* <h4 className="avatartitle">Avatar:</h4> */}
                    <div className="avatar-selector">
                        <div className="avatar-list">
                        {Avatars.map((e, j) => (
                          
                          
                         
                         
                          // if random has


                           // if e.date exists . . we need to check the array
                           <div key={j+e.type}>
                            {/* if criteria is 1 - show it */}
                            { e.criteria === 1  &&
                                <FormGroup check key={j+e.type}>
                                <Label check>
                                <Input
                                  name="avatar"
                                  disabled={isDisabled(e)}
                                  type="radio"
                                  value={e.type}
                                  checked={userMetaData.avatar === e.type}
                                  onChange={(e) => {handleChangeForm(e)}}
                                />{' '}
                                 <Avatar address={user.email} avatar={e.type} active={userMetaData.avatar === e.type}/>
                                 <span>{e.name}</span>
                                </Label>
                              </FormGroup>
                            } 
                         {/* // if criteria is > 1 and points equal criteria - show it */}
                         { e.criteria > 1  && userWalletInfo?.totalPoints > e.criteria &&
                            <FormGroup check key={j+e.type}>
                              <Label check>
                                <Input
                                  name="avatar"
                                  disabled={isDisabled(e)}
                                  type="radio"
                                  value={e.type}
                                  checked={userMetaData.avatar === e.type}
                                  onChange={(e) => {handleChangeForm(e)}}
                                />{' '}
                                  <Avatar address={user.email} avatar={e.type} />
                                  <span>{e.name}</span>
                              </Label>
                            </FormGroup>
                            } 

                          {/* // if date is not underfined - and ifhasdate is true - show it */}
                          { e.date !== undefined  && ifHasDate(e.date, userMetaData?.metaData?.checkins) &&
                            <FormGroup check key={j+e.type}>
                              <Label check>
                                <Input
                                  name="avatar"
                                  disabled={isDisabled(e)}
                                  type="radio"
                                  value={e.type}
                                  checked={userMetaData.avatar === e.type}
                                  onChange={(e) => {handleChangeForm(e)}}
                                />{' '}
                                  <Avatar address={user.email} avatar={e.type} />
                                  <span>{e.type}</span>
                              </Label>
                            </FormGroup>
                            } 

                          {/* { e.criteria > 1  && userMetaData?.metaData?.checkins.length < e.criteria &&
                            <FormGroup check key={j+e.type}>
                              <Label check>
                                <Input
                                  name="avatar"
                                  disabled={isDisabled(e)}
                                  type="radio"
                                  value={e.type}
                                  checked={userMetaData.avatar === e.type}
                                  onChange={(e) => {handleChangeForm(e)}}
                                />{' '}
                                 
                                  <FontAwesomeIcon icon={faUnlock} />
                                  <span> @ {e.criteria} Points</span>
                              </Label>
                            </FormGroup>
                            }  */}

                           {/* { e.hide !== true  &&
                            <FormGroup check key={j+e.type}>
                              <Label check>
                              <Input
                                name="avatar"
                                disabled={isDisabled(e)}
                                type="radio"
                                value={e.type}
                                checked={userMetaData.avatar === e.type}
                                onChange={(e) => {handleChangeForm(e)}}
                              />{' '}
                                { e.date !== undefined ?
                                    // award has date
                                  ifHasDate(e.date, userMetaData?.metaData?.checkins) ?
                                    // <span>{e.type}</span>
                                    <Avatar address={user.email} avatar={e.type} />
                                  :
                                    <span><FontAwesomeIcon icon={faUnlock} /> on {moment(e.date).format("MM/DD/YYYY")}</span>
                                :
                                  // award has no date
                                  e.criteria > userMetaData?.metaData?.checkins.length ? 
                                    <span><FontAwesomeIcon icon={faUnlock} /> at {e.criteria} Checkins</span>
                                  :  <Avatar address={user.email} avatar={e.type} />

                              }
                                      
                                
                              </Label>
                            </FormGroup>
                           } */}
                        
                        </div>
                        ))}
                        { userMetaData?.easterEggs?.includes('konami') && 
                          <FormGroup check>
                            <Label check>
                            <Input
                              name="avatar"
                              disabled={false}
                              type="radio"
                              value={'konami'}
                              checked={userMetaData.avatar === 'konami'}
                              onChange={(e) => {handleChangeForm(e)}}
                            />{' '}
                              <Avatar address={user.email} avatar='konami' />
                              <span>Konami</span>
                            </Label>
                            </FormGroup>
                        }                      
                        </div>
                        {/* <div className="avatar-image-container">
                            
                            <span className="preview-image">PREVIEW:</span>
                        </div> */}
                    </div>
                
                </FormGroup>
            </Form>
                <div className="avatar-saver">
                  <Button className="clicklink linker" style={{marginTop: '0px'}} disabled={userMetaData.userName === ''} onClick={() => saveUser()}>SAVE</Button>
                  <Button className="clicklink linker greygoose" style={{marginTop: '0px'}} onClick={() => cancelSave()}>CANCEL</Button>
                </div>
              </div>
            
            </Dialog>
            </DialogBackdrop>


            <DialogBackdrop {...dialogBadge}  className="backstyle">
            <Dialog className="refix" {...dialogBadge} aria-label="Welcome">
              <h3 className="claimh claimg smtitle">Badge Details</h3>
              <Button className="closebtn" onClick={dialogBadge.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
              <div className="flutarsky">
               <div className="childtarsky">
                  <div className="imgholdy zero-top">
                    <div className={'leader-rank newbadge newbig ' + currentBadgeInfo.image}></div>
                  </div>
                  { currentBadgeInfo.level > 0 && 
                  <div className="holdertarsky modslvl">
                    <span >Level {currentBadgeInfo.level} Reward</span>
                  </div>
                  }
                  <div className="holdertarsky bigtitle">
                    {/* <span className="lab">Reward Name:</span> */}
                    {currentBadgeInfo.name}
                    <p className="rewards-description">{currentBadgeInfo.description}</p>
                  </div>                 
                
                  <div className="holdertarsky">
                    <span className="lab">Reward Earned:</span>
                    {currentBadgeInfo.reward}
                  </div>

                  {currentBadgeInfo.pointTotal &&
                    <div className="holdertarsky">
                      <span className="lab">Total Points Earned:</span>
                      {currentBadgeInfo.pointTotal}
                    </div>
                  }
                  
                
                  {currentBadgeInfo.claimed && currentBadgeInfo.type === 'physical' &&
                    <div className="status-rew claimed">CLAIMED</div> 
                  }
                  {/* <div className="status-rew expired">EXPIRED</div> */}
                  {!currentBadgeInfo.claimed && currentBadgeInfo.type === 'physical' &&
                    <div className="status-rew activer">REDEEMABLE</div>
                  }

                  <TwitterShareButton className="social-share zeropadtop" hashtags={['tacoshop', 'loyaltyrewards']} url="https://rewards.lucypackmagic.com/" title={'I just earned the "' + currentBadgeInfo.name + '" reward! '}>
                    <p className="clicklink linker close-numy share-twitty">Share on Twitter <TwitterIcon className="twitty" size={30} round={true} /></p>   
                  </TwitterShareButton>
               </div>
              </div>
            
            </Dialog>
            </DialogBackdrop>

            <DialogBackdrop {...dialogKonami}  className="backstyle">
            <Dialog className="refix konegg" {...dialogKonami} aria-label="Welcome">
              {/* <h3 className="claimh claimg">Badge Details</h3> */}
              {/* <Button className="closebtn" onClick={dialogKonami.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button> */}
              <div className="flutarsky">
                <h1 className="egg-header">Achievement Unlocked</h1>
                <span className="sub-egg-header"><strong>Player 1: </strong> You have proved yourself a worthy opponent. For that you have been granted a new avatar.</span>
                <div className="childtarsky eggbgd">
                  
                </div>
                <div className="eggclose" onClick={dialogKonami.hide}><FontAwesomeIcon icon="times" className="eggclosebtn" /> Close</div>
              </div>
            
            </Dialog>
            </DialogBackdrop>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
