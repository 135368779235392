import React, { useState } from "react";
import WheelComponent from "react-wheel-of-prizes";
import Confetti from 'react-confetti'; // Adding Confetti effect on winning spin
import useWindowSize from 'react-use/lib/useWindowSize'; // For window resizing on Confetti

import spinon from "../assets/spin-on.png"; // Custom Spin button
import spinfin from "../assets/spin-win.png"; // Custom Win button
import spinoff from "../assets/spin-off.png"; // Custom default button

const CustomPrizeWheel = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prize, setPrize] = useState(0);
  const { width, height } = useWindowSize();

  // Define your custom prize data
  const data = [
    { option: "Sealed Pack", imgSrc: "assets/levelninereward.png", color: "#52376f" },
    { option: "Stickers", imgSrc: "/assets/levelninereward.png", color: "#6b4f91" },
    { option: "App Avatars", imgSrc: "/path-to-image/avatars.png", color: "#805ba9" },
    { option: "EX/V Cards", imgSrc: "/path-to-image/cards.png", color: "#9d76c1" },
    { option: "Full Art Cards", imgSrc: "/path-to-image/full-art.png", color: "#b892d8" },
    { option: "Slab", imgSrc: "/path-to-image/slab.png", color: "#d1aeea" },
  ];

  const segments = [
    "Sealed Pack",
    "Stickers",
    "App Avatars",
    "EX/V Cards",
    "Full Art Cards",
    "Slab"
  ];
  const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];
  const onFinished = (winner) => {
    setPrize(winner)
    console.log(winner);
  };

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  return (
    <div style={{ textAlign: "center", position: "relative" }}>
      {/* Show confetti when the wheel stops spinning */}
      {/* { !mustSpin && prizeNumber !== null && (
        <Confetti width={width} height={height} numberOfPieces={60} />
      )} */}

      {/* Title */}
      <h1>Spin the Wheel!</h1>

      {/* Prize Wheel */}
      <div style={{ display: "inline-block", position: "relative" }}>
      <WheelComponent
          segments={segments}
          segColors={segColors}
          onFinished={(winner) => onFinished(winner)}
          primaryColor="black"
          contrastColor="white"
          buttonText="Spin"
          isOnlyOnce={false}
          size={190}
          upDuration={500}
          downDuration={600}
          fontFamily="Arial"
        />
      </div>

      {/* Display Prize Name */}
      <div style={{ marginTop: "20px" }}>
        {mustSpin ? <p>Spinning...</p> : <p>Prize: {data[prizeNumber]?.option}</p>}
      </div>
    </div>
  );
};

export default CustomPrizeWheel;
