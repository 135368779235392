import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import Qrcodes from "../src/check-code.json";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Qr from "./views/qr";
import Lobby from "./views/Lobby";
import AdminComponent from "./views/Admin";
import CheckLink from "./views/check-link";
import LobbyPurchase from "./views/LobbyPurchase";
import Profile from "./views/Profile";
import Checkin from "./views/Checkin";
import Partners from "./views/Partners";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import moment from 'moment';
import Game from './Game';
import SpinningWheel from './components/SpinningWheel';
import CustomPrizeWheel from './components/CustomPrizeWheel';
import PrizeWheel from './components/PrizeWheel';

// styles
import "./App.css";
import "./dapp.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();


const App = () => {
  const currentDay = moment().day();
  let currentUrl = '';
  Qrcodes.forEach((item) => {
    if(item.date === currentDay) {
      currentUrl = item.value;
    };
  });

  const { isLoading, error } = useAuth0();
  const pathName = window.location.pathname;
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className={ pathName === '/scan' ? 'd-flex flex-column h-100 qrcodepage' : 'd-flex flex-column h-100'}>
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/checkin" component={Checkin} />
            <Route path="/lobby" component={Lobby} />
            <Route exact path="/lobby-purchase/:userId" component={LobbyPurchase} />
            <Route path="/profile" component={Profile} />
            <Route path="/partners" component={Partners} />
            {/* <Route path="/spin" component={SpinningWheel} /> */}
            <Route path="/spin2" component={CustomPrizeWheel} />
            <Route path="/spin" component={PrizeWheel} />
            <Route path="/games" component={Game} />
            <Route path="/admin" component={AdminComponent} />
            <Route path="/scan" component={Qr} />
            <Route path={"/check-link" + currentUrl} component={CheckLink} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
