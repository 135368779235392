import React, { useState } from "react";
import spinon from "../assets/spin-on.png"; // Ensure the path is correct
import spinfin from "../assets/spin-win.png"; // Ensure the path is correct
import spinoff from "../assets/spin-off.png"; // Ensure the path is correct

const Spinner = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);

  // List of prizes
  const prizes = [
    "PACK",
    "EX CARD",
    "ISR CARD",
    "AVATAR",
    "POINTS",  // New category 1
    "SLAB", // New category 2
  ];

  const spinWheel = () => {
    if (isSpinning) return;  // Prevent multiple spins at once

    setIsSpinning(true);  // Start spinning

    const selected = Math.floor(Math.random() * prizes.length);  // Random prize selection

    const rotation = 360 * 3 + (selected * 360) / prizes.length;  // Full rotations + target slice
    const wheel = document.querySelector(".wheel");

    wheel.style.transition = "transform 3s ease-out";
    wheel.style.transform = `rotate(${rotation}deg)`;  // Animate the spin

    setTimeout(() => {
      wheel.style.transition = "none";
      wheel.style.transform = `rotate(${(selected * 360) / prizes.length}deg)`;  // Reset wheel to selected slice
      setSelectedPrize(prizes[selected]);  // Set the selected prize
      setIsSpinning(false);  // End spinning
    }, 3000);
  };

  return (
    <div className="spinner-container">
      {/* Spin button, conditionally display spin-off or spin-on image */}
      <img
        src={isSpinning ? spinon : selectedPrize ? spinfin : spinoff}
        alt="Spin Button"
        className="spin-lu"
        onClick={!isSpinning ? spinWheel : undefined}
        style={{ cursor: isSpinning ? "not-allowed" : "pointer" }}
      />

      {/* The spinning wheel container */}
      <div className="wheel-container">
        <div className="wheel">
          {prizes.map((prize, index) => {
            const sliceAngle = 360 / prizes.length; // Ensuring even slices
            const rotation = index * sliceAngle;
            const labelRotation = rotation + sliceAngle / 2;  // Position the label at the center of each slice

            return (
              <div
                key={index}
                className="slice"
                style={{
                  transform: `rotate(${rotation}deg)`,
                  // Ensure even conic gradient with exact slice size
                  background: `conic-gradient(
                    ${index % 2 === 0 ? "#f39c12" : "#d35400"} ${rotation}deg,
                    ${index % 2 === 0 ? "#f39c12" : "#d35400"} ${rotation + sliceAngle}deg
                  )`,
                }}
              >
                <span
                  className="slice-label"
                  style={{
                    transform: `rotate(${labelRotation}deg)`,  // Rotate the label so it's upright
                    position: "absolute",  // Position label inside the slice
                    left: "50%",
                    top: "50%",
                    transform: `translate(-50%, -50%) rotate(${labelRotation}deg)`,  // Center and rotate label
                    // fontSize: "12px", // Adjust font size as needed
                    fontWeight: "bold",
                    color: "white",  // Ensure the text color is visible
                    textAlign: "center",  // Center text horizontally
                    width: "80%",  // Control text inside slice
                  }}
                >
                  {prize}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* Spin Now button, disabled while spinning */}
      <button onClick={spinWheel} disabled={isSpinning}>
        {isSpinning ? "..." : "Spin Now!"}
      </button>

      {/* Display the selected prize */}
      {selectedPrize && <div className="result">You won: {selectedPrize}!</div>}
    </div>
  );
};

export default Spinner;
