import '../SpinningWheel.css';
import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import Loading from "../components/Loading";
import { db } from '../firebase';
import { Wheel } from "react-custom-roulette";
import spinon from "../assets/spin-on.png";
import spinfin from "../assets/spin-win.png";
import spinoff from "../assets/spin-off.png"; 
import stickers from "../assets/stickers.png"; 
import psacard from "../assets/psacard.jpg"; 
import spincircle from "../assets/sping-bgd.png";
import avatars from "../assets/avatarsc.png";
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

const PrizeWheel = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [currentUserforInfo, setCurrentUserforInfo] = useState(undefined);
  const { width, height } = useWindowSize();
  const { user } = useAuth0();

  const SPIN_COST = 20;

  // Prize data
  const data = [
    { option: "Sealed Pack", style: { backgroundColor: "#46a828", textColor: "#ffffff" } },
    { 
      option: "Stickers", 
      style: { backgroundColor: "#fdd926", textColor: "#ffffff" },
      image: {
        uri: stickers,
        offsetX: 0,
        offsetY: 0,
        sizeMultiplier: 1,
        landscape: false,
      },
    },
    { 
      option: "App Avatars", 
      image: {
        uri: avatars,
        offsetX: 0,
        offsetY: 0,
        sizeMultiplier: 1.5,
        landscape: false,
      },
      style: { backgroundColor: "#2285e6", textColor: "#ffffff" }
    },
    { option: "EX/V Cards", style: { backgroundColor: "#f51f2f", textColor: "#ffffff" } },
    { option: "Full Art Cards", style: { backgroundColor: "#5a3ea3", textColor: "#ffffff" } },
    { 
      option: "Graded Slab", 
      image: {
        uri: psacard,
        offsetX: 0,
        offsetY: 0,
        sizeMultiplier: 1,
        landscape: false,
      },
      style: { backgroundColor: "#eb5e2a", textColor: "#ffffff" }
    },
  ];

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  useEffect(() => {
    getUserStuff();
  }, []);

  const getUserStuff = async () => {
    const docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const tempUserData = docSnap.data();
      setCurrentUserforInfo(tempUserData);
    }
  };

  const updatePointsAndPrizes = async () => {
    if (!currentUserforInfo) return;

    const updatedClaimedPoints = (currentUserforInfo.claimedPoints || 0) + SPIN_COST;
    const currentPrize = data[prizeNumber].option;
    const updatedPrizes = [
      ...(currentUserforInfo.prizesClaimed || []),
      currentPrize,
    ];

    try {
      const docRef = doc(db, "purchasedTokens", user.nickname);
      await updateDoc(docRef, {
        claimedPoints: updatedClaimedPoints,
        prizesClaimed: updatedPrizes,
      });

      // Update state
      setCurrentUserforInfo((prev) => ({
        ...prev,
        claimedPoints: updatedClaimedPoints,
        prizesClaimed: updatedPrizes,
      }));
    } catch (error) {
      console.error("Error updating points and prizes:", error);
    }
  };

  const canSpin = () => {
    if (!currentUserforInfo) return false;
    const { totalPoints, claimedPoints = 0 } = currentUserforInfo;
    return totalPoints - claimedPoints >= SPIN_COST;
  };

  return (
    <>
      {!mustSpin && prizeNumber && (
        <Confetti width={width} height={height} numberOfPieces={60} />
      )}
      <div className='charty' style={{ textAlign: "center", marginTop: "50px", position: "relative" }}>
        <img
          src={mustSpin ? spinon : prizeNumber ? spinfin : spinoff}
          alt="Spin Button"
          className="spin-lu"
          onClick={canSpin() ? handleSpinClick : null}
          style={{ cursor: canSpin() ? "pointer" : "not-allowed", opacity: canSpin() ? 1 : 0.5 }}
        />
        <h1 className='newheader'>Spin the Wheel!</h1>
        <div style={{ display: "inline-block", position: "relative" }}>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            onStopSpinning={() => {
              setMustSpin(false);
              updatePointsAndPrizes(); // Update points and prizes after spinning
            }}
            textColors={["#ffffff"]}
            outerBorderColor="#52376f"
            innerBorderColor="white"
            outerBorderWidth={6}
            innerRadius={10}
            radiusLineColor="#2b2b2b"
            radiusLineWidth={3}
            textDistance={70}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          {mustSpin ? <p>Spinning...</p> : ''}
        </div>
        {prizeNumber !== null && !mustSpin && (
          <div className="prize-display">
            <h3>Congratulations! <br />You won: {data[prizeNumber].option}</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(PrizeWheel, {
  onRedirecting: () => <Loading />,
});
