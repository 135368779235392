import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/LPM.png";
import { db } from '../firebase';
import { getDoc, doc } from "firebase/firestore";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Button
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
    const [adminRole, setadminRole] = useState(false);

  const gettingUserInfo = async () => {
    if(!isAuthenticated) {
      return;
    }
    const docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const tempUserData = docSnap.data();
      if(tempUserData.admin) {
        setadminRole(true);
      }
    }
  }
  // const gettingUserInfoFromFullList = async () => {
  //   if(!isAuthenticated) {
  //     return;
  //   }
  //   let tempTokenHolderArray = [];
  //   let tempUserData = {};
  //   console.log('userr', user);
  //   const querySnapshot = await getDocs(collection(db, "purchasedTokens"));   
  //   querySnapshot.forEach((doc) => {
  //     let item = doc.data();
  //     item.id = doc.id;
  //     tempTokenHolderArray.push(item);
  //   });
  //   tempTokenHolderArray.forEach((holder) => {
  //     // console.log('holder.emailAddress.toLowerCase(); ', holder.emailAddress.toLowerCase());
  //     // console.log('user.email ', user.email);
  //     tempUserData = holder;
  //     const formedEmail = holder.emailAddress.toLowerCase();
  //     if (formedEmail === user.email) {
  //       // USER HAS A TOKEN
  //       console.log('we got an admin ho', holder);
  //       if(holder.admin) {
  //         setadminRole(true);
  //       }
        
  //     }
  //   });
  // };

  useEffect(() => {
    gettingUserInfo();
  }, []);

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container className="topstuff">
          <RouterNavLink
            to="/"
            onClick={toggle}
            className="logoholder"
          >
            {/* <span className="bblogo">Enlightened Rewards</span> */}
            <img alt="logo" className="mb-3 app-logo updates" src={logo} width="120" />
            <span className="sublogo"><strong>Loyalty Rewards</strong></span>
          </RouterNavLink>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
            {isAuthenticated && adminRole && (
              <>
                <NavItem>
                  <RouterNavLink
                    to="/lobby"
                    activeClassName="router-link-exact-active"
                    onClick={toggle}
                  >
                    <FontAwesomeIcon icon="users" className="mr-3" />
                    Lobby
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                <RouterNavLink
                  to="/admin"
                  activeClassName="router-link-exact-active"
                  onClick={toggle}
                >
                  <FontAwesomeIcon icon="cog" className="mr-3" />
                  Admin
                </RouterNavLink>
              </NavItem>
              </>
            )}
              <NavItem>
              
                <RouterNavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  onClick={toggle}
                  activeClassName="router-link-exact-active"
                >
                  <FontAwesomeIcon icon="star" className="mr-3" />
                  Leaderboard
                </RouterNavLink>
              </NavItem>
              {/* {isAuthenticated && (
              <NavItem>
                <FontAwesomeIcon icon="people-arrows" className="mr-3" />
                <RouterNavLink
                  to="/partners"
                  activeClassName="router-link-exact-active"
                  onClick={toggle}
                >
                  Partners
                </RouterNavLink>
              </NavItem>
              )} */}
          {isAuthenticated && (
            <>
              <NavItem>
                 
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                    onClick={toggle}
                  >
                    <FontAwesomeIcon icon="user" className="mr-3" />
                    Profile
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                
                <RouterNavLink
                  to="/spin"
                  activeClassName="router-link-exact-active"
                  onClick={toggle}
                >
                  <FontAwesomeIcon icon="gift" className="mr-3" />
                  Spin!
                </RouterNavLink>
              </NavItem>
              </>
            )}
             {/* <NavItem>
              <FontAwesomeIcon icon="gamepad" className="mr-3" />
                <RouterNavLink
                  tag={RouterNavLink}
                  to="/games"
                  exact
                  onClick={toggle}
                  activeClassName="router-link-exact-active"
                >
                  Games
                </RouterNavLink>
              </NavItem> */}
             {isAuthenticated && (
                <NavItem>
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    <FontAwesomeIcon icon="power-off" className="mr-3" />
                    Log out
                  </RouterNavLink>
                </NavItem>
            )}
            
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    <FontAwesomeIcon icon="power-on" className="mr-3" />
                    Log in / Sign Up
                  </Button>
                </NavItem>
              )}
              {/* {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )} */}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                <FontAwesomeIcon icon="power-on" className="mr-3" />
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
           
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
